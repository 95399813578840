// Copyright 2016 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Directive for general schema-based editors.
 */
require('components/forms/schema-based-editors/' +
    'schema-based-bool-editor.directive.ts');
require('components/forms/schema-based-editors/' +
    'schema-based-choices-editor.directive.ts');
require('components/forms/schema-based-editors/' +
    'schema-based-custom-editor.directive.ts');
require('components/forms/schema-based-editors/' +
    'schema-based-dict-editor.directive.ts');
require('components/forms/schema-based-editors/' +
    'schema-based-float-editor.directive.ts');
require('components/forms/schema-based-editors/' +
    'schema-based-html-editor.directive.ts');
require('components/forms/schema-based-editors/schema-based-int-editor.directive.ts');
require('components/forms/schema-based-editors/' +
    'schema-based-list-editor.directive.ts');
require('components/forms/schema-based-editors/' +
    'schema-based-unicode-editor.directive.ts');
require('domain/utilities/UrlInterpolationService.ts');
var oppia = require('AppInit.ts').module;
oppia.directive('schemaBasedEditor', [
    'UrlInterpolationService', function (UrlInterpolationService) {
        return {
            scope: {
                schema: '&',
                isDisabled: '&',
                localValue: '=',
                labelForFocusTarget: '&',
                onInputBlur: '=',
                onInputFocus: '='
            },
            templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/components/forms/schema-based-editors/' +
                'schema-based-editor.directive.html'),
            restrict: 'E'
        };
    }
]);
